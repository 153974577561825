import { Controller } from "react-hook-form";
import { Checkbox } from "@/components/checkbox";
import { GreaterAffixCheckbox as AffixCheckbox } from "@/components/greater-affix-checkbox";

export function Affix({
  affixTitle,
  statIndex,
  affixIndex,
}: {
  affixTitle: string;
  statIndex: number;
  affixIndex: number;
}): JSX.Element {
  return (
    <div className="grid h-8 grid-cols-2 gap-2">
      <div className="flex items-center gap-2">
        <AffixEnabledCheckbox affixIndex={affixIndex} statIndex={statIndex} />

        <GreaterAffixEnabledCheckbox
          affixIndex={affixIndex}
          statIndex={statIndex}
        />

        <span className="font-diablo">{affixTitle}</span>
      </div>
      <div className="grid grid-cols-2">
        <AffixMinInput affixIndex={affixIndex} statIndex={statIndex} />
        <AffixMaxInput affixIndex={affixIndex} statIndex={statIndex} />
      </div>
    </div>
  );
}

function AffixEnabledCheckbox({
  statIndex,
  affixIndex,
}: {
  statIndex: number;
  affixIndex: number;
}): JSX.Element {
  return (
    <Controller
      name={`statFilters.${statIndex}.affixes.${affixIndex}.enabled`}
      render={({ field }) => {
        return (
          <Checkbox
            checked={Boolean(field.value)}
            onCheckedChange={(checked) => {
              field.onChange(checked);
            }}
          />
        );
      }}
    />
  );
}

function GreaterAffixEnabledCheckbox({
  statIndex,
  affixIndex,
}: {
  statIndex: number;
  affixIndex: number;
}): JSX.Element {
  return (
    <Controller
      name={`statFilters.${statIndex}.affixes.${affixIndex}.greaterAffixEnabled`}
      render={({ field }) => {
        return (
          <AffixCheckbox
            checked={Boolean(field.value)}
            className="h-4 w-4"
            onCheckedChange={(checked) => {
              field.onChange(checked);
            }}
          />
        );
      }}
    />
  );
}

function AffixMinInput({
  statIndex,
  affixIndex,
}: {
  statIndex: number;
  affixIndex: number;
}): JSX.Element {
  return (
    <Controller
      name={`statFilters.${statIndex}.affixes.${affixIndex}.min`}
      render={({ field }) => {
        return (
          <input
            {...field}
            className="border border-gray-700 bg-gray-600 px-2 font-diablo text-xs text-gray-50 placeholder:text-gray-50 focus:outline-none"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.target.value = e.target.value.replace(/[^-?\d]/g, "");
              field.onChange(e);
            }}
            placeholder="MIN"
            value={field.value as string}
          />
        );
      }}
    />
  );
}

function AffixMaxInput({
  statIndex,
  affixIndex,
}: {
  statIndex: number;
  affixIndex: number;
}): JSX.Element {
  return (
    <Controller
      name={`statFilters.${statIndex}.affixes.${affixIndex}.max`}
      render={({ field }) => {
        return (
          <input
            {...field}
            className="border border-gray-700 bg-gray-600 px-2 font-diablo text-xs text-gray-50 placeholder:text-gray-50 focus:outline-none"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.target.value = e.target.value.replace(/[^-?\d]/g, "");
              field.onChange(e);
            }}
            placeholder="MAX"
            value={field.value as string}
          />
        );
      }}
    />
  );
}
